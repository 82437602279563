<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :items="profit_data"
      :items-per-page="10"
      item-key="id"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="h-screen"
      fixed-header
      dense
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
      <template v-slot:top>
        <v-toolbar flat>
          <AddData />
          <v-btn rounded color="info ml-2" dark @click="printSection">
            <v-icon left>
              mdi-printer
            </v-icon>
            พิมพ์
          </v-btn>
          <v-divider class="mx-4 info" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn rounded outlined color="pink">
            <v-icon left>
              mdi-filter-variant
            </v-icon>
            ตัวกรอง
          </v-btn>
        </v-toolbar>
        <!-- <v-alert dense text class="text-center" type="info">
          แสดง <strong>ทั้งหมด</strong> รวม
          <strong>{{ profit_count }}</strong> รายการ
        </v-alert> -->
      </template>
      <template slot="header">
        <thead>
          <tr>
            <th colspan="1" class="h_id" style="width: 8%;">
              ประเภท
            </th>
            <th
              colspan="1"
              class="h_num text-center grey lighten-2 white-space: normal"
              style="width: 10%;"
            >
              ยอดขาย
            </th>
            <th
              class="h_betprice text-center grey lighten-4 white-space: normal"
              colspan="1"
              style="width: 10%;"
            >
              ยอดส่งออก
            </th>
            <th
              class="h_status text-center grey lighten-2"
              colspan="2"
              style="width: 18%;"
            >
              กำไรสูงสุด
            </th>
            <th
              class="h_betsum text-center grey lighten-4"
              colspan="2"
              style="width: 18%;"
            >
              ขาดทุนสูงสุด
            </th>
            <th
              class="h_pricesum text-center grey lighten-2"
              :colspan="1"
              style="width: 15%;"
            >
              อัตราส่วนกำไร/ขาดทุน
            </th>
            <th
              class="h_winsum text-center grey lighten-4"
              :colspan="1"
              style="width: 15%;"
            >
              เบอร์กำไร/ขาดทุน
            </th>
            <th
              class="h_bettime text-center grey lighten-2"
              :colspan="1"
              style="width: 5%;"
            >
              โอกาส
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item="{ item }">
        <!-- @contextmenu="popup_menu($event, item)" -->
        <tr
          @touchstart="touch = true"
          @click="row_click($event, item)"
          @contextmenu="popup_menu($event, item)"
          :class="{ 'pink lighten-5': item.id === selectedId }"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->
          <td :class="[id_column, getIDColor(item.id)]">
            {{ getBetTypeText(item.id) }}
          </td>
          <td align="end">
            {{
              item.bet_sum
                .toString()
                .replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            }}
          </td>
          <td align="end">
            {{
              item.exp_sum
                .toString()
                .replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            }}
          </td>
          <td align="end">
            {{
              item.max_get
                .toString()
                .replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            }}
          </td>
          <td align="center" class="green--text text--darken-4">{{ item.num_max_get }}</td>
          <td align="end">     {{
              item.max_loss
                .toString()
                .replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            }}</td>
          <td align="center" class="deep-orange--text text--darken-4">{{ item.num_max_loss }}</td>
          <td align="center" >1.00 : {{ (item.max_loss/item.max_get).toFixed(2) * -1 }}</td>
          <td align="center">{{ item.num_get_count }} / {{ item.num_loss_count }}</td>
          <td align="center">
            {{ ((item.num_get_count*100)/ (item.num_get_count+item.num_loss_count)).toFixed(2)}}%
          </td>
        </tr>
      </template>

      <template v-slot:footer>
        <tr class="black--text">
          ยอดขาย และ ยอดส่งออก คือยอดแทงมาไม่หัก %
        </tr>
      </template>
    </v-data-table>

    <PopupMenu ref="PopupMenu1" />
  </div>
</template>

<script>
//import PopupMenu from "@/components/Cus/Line/Genaral/Popup_Menu";
import PopupMenu from "@/components/Data/Export/Export_Begin/Profit_Loss/Popup_Menu";
import AddData from "@/components/Cus/Line/Genaral/Add_Data";

export default {
  name: "Bookie_Datatable",

  components: {
    PopupMenu,
    AddData,
  },
  created() {
    //โหลด ตาราง
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    profit_data() {
      return this.$store.getters["export_process/Profits"];
    },
    profit_count() {
      return 0; //this.$store.getters["bookie/Bookies"].length;
    },
  },
  methods: {
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        ListType: "profit_loss_list",
      };
      await this.$store
        .dispatch("export_process/get_profit_list", credentials)
        .then(
          (response) => {
            //console.log(response);
          },
          (error) => {
            console.log(error);
          }
        );
    },
    getBetTypeText(betType) {
      const statusText = [
        "รวมบน",
        "3 ตัวบน",
        "3 ตัวโต๊ด",
        "2 ตัวบน",
        "2 ตัวล่าง",
        "คู่โต๊ด",
        "",
        "พวง",
        "",
        "จม บ.น.",
        "จม บ.ก.",
        "จม บ.ท.",
        "จม. ล.น.",
        "จม. ล.ท.",
        "ลอยบน",
        "ลอยล่าง",
        "คู่หน้า",
        "คู่ถ่าง",
      ];
      if(betType==-1) return "รวมล่าง";
      if(betType==0) return "รวมบน";
      return statusText[parseInt(betType)];
    },
    getIDColor(betType) {
      const statusText = [
        "",
        "indigo",
        "indigo",
        "indigo",
        "teal",
        "indigo",
        "",
        "indigo",
        "",
        "indigo",
        "indigo",
        "indigo",
        "teal",
        "teal",
        "indigo",
        "teal",
        "indigo",
        "indigo",
      ];
      if(betType==-1) return "pink";
      if(betType==0) return "pink";
      return statusText[parseInt(betType)];
    },
    getStatusText(status) {
      const statusText = ["ปกติ", "ระงับ"];
      return statusText[parseInt(status)];
    },
    getStatusColor(status) {
      const statusColor = ["green--text", "red--text"];
      return statusColor[parseInt(status)] + " pl-10";
    },
    getNamesColor(status) {
      const statusColor = ["", "red--text"];
      return statusColor[parseInt(status)];
    },
    row_click: function(e, item) {
      if (this.selectedId == item.id) {
        if (this.touch == true) this.popup_menu(e, item);
      }

      this.selectedId = item.id;
      this.$set(item, "selected", true);
    },
    popup_menu: function(e, item) {
      e.preventDefault();
      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.Show(item.id);
        this.selectedId = item.id;
        //console.log(item);
      });
    },
    printSection() {
      this.$htmlToPaper("printSection");
    },
  },
  data: () => ({
    selectedId: -1,

    touch: false,
    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,
    id_column: "id_column_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  color: white;
   text-align: left !important;  
    padding-top: 3px !important;
  /* position:absolute;  */
  border-color: lightgray !important;
  position: sticky !important;
  left: 0;
}

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: rgb(250, 242, 255) !important;
  color: rgb(43, 42, 42) !important;
  padding-top: 0px !important;
}

::v-deep table thead th:last-child {
  padding-left: 40px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
  font-size: 16px !important;
}

::v-deep table {
  background-color: #fafafa;
}

::v-deep table tbody tr td {
  font-size: 1em !important;
  color: rgb(0, 0, 0);
  font-weight: normal;
  border-right: 1px solid;
  border-color: lightgray;
}

@media only screen and (min-width: 850px) {
  ::v-deep table tbody tr td {
    font-size: 0.9em !important;
    color: rgb(88, 88, 88);
    font-weight: bold;
  }
}
</style>
