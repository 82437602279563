<template>
  <div>
    <v-app-bar dense fixed app color="pink darken-4" dark>
      <v-btn icon to="/menu_data">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title>สรุปรวมก่อนส่งออก</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-app-bar>

    <MenuTab @onTabChange="onTabChange" />

    <component v-bind:is="component" />
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import GenaralTable from "@/components/Data/Export/Export_Begin/Profit_Loss/Datatable";
import CurrentTable from "@/components/Cus/Line/Current/Datatable";
import AccountTable from "@/components/Cus/Line/Account/Datatable";
import EarlyExport from "@/components/Data/Export/Export_Begin/EarlyExport/Datatable";
import MenuTab from "@/components/Data/Export/Export_Begin/Layout/Menu_Tab";

export default {
  name: "Line_Data",
  mixins: [globalFunctionMixin],
  components: {
    GenaralTable,
    CurrentTable,
    AccountTable,
    MenuTab,
  },
  mounted() {
    this.ckLogin();
    this.bc_items[0].text = sessionStorage.getItem("host_id");
  },
  data: () => ({
    component: "GenaralTable",

    bc_items: [
      {
        text: "a1 ถิง",
        disabled: false,
      },
    ],
  }),
  //   { text: "ทั่วไป", id: 1 },
  //   { text: "เปอร์เซ็นต์/อัตราจ่าย", id: 2 },
  //   { text: "เลขอั้นตามเจ้า", id: 3 },
  //   { text: "เลขอั้นตามเบอร์", id: 4 },
  //   { text: "บัญชี", id: 5 },
  //   { text: "บันทึก", id: 6 },
  methods: {
    onTabChange(id) {
      switch (id) {
        case 1:
          this.component = GenaralTable;
          break;
        case 2:
          this.component = CurrentTable;
          break;
        case 5:
          this.component = EarlyExport;
        //   case 3:
        //     this.component = HPByBookie;
        //     break;
      }
      // alert(id)
    },
  },
};
</script>

<style></style>
